import { combineReducers } from 'redux';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';
import FormReducer from './core/form/reducer';
import LoginReducer from './auth/login/reducer';
import ForgotPasswordReducer from './auth/forgotpassword/reducer';
import ResetPasswordReducer from './auth/resetpassword/reducer';
import UserReducer from './apps/users/reducer';
import vechicles from './apps/vechicles/reducer';
import Logistics from './apps/logistics_Report/reducer';
import stockReport from './apps/WareHouse/reducer'
import Production from './apps/Production_Report/reducers';
import PalletReport from './apps/pallet_Report/reducer';
import LoadingAdviceReport from './apps/loadingAdviceReport/reducer';
// import LockerReducer from './apps/lockers/reducer';
// import CustomerReducer from './apps/customers/reducer';
// import GarmentReducer from './apps/garments/reducer';
// import EmployeeReducer from './apps/employees/reducer';
import UserDetailsReducer from './apps/userDetails/reducer';
// import CustomerDetailsReducer from './apps/customerDetails/reducer';
// import GarmentDetailsReducer from './apps/garmentDetails/reducer';
// import LockerDetailsReducer from './apps/lockerDetails/reducer';
// import WorkOrderReducer from './apps/workOrders/reducer';
// import WorkOrderDetailsReducer from './apps/workOrderDetails/reducer';
// import EmployeeDetailsReducer from './apps/employeeDetails/reducer';
// import DepartmentReducer from './apps/departments/reducer';
// import departmentDetailsReducer from './apps/departmentDetails/reducer';
// import ReasonReducer from './apps/reasons/reducer';
import OrganisationReducer from './apps/organisations/reducer';
// import InventoryReducer from './apps/inventory/reducer';
import RoleReducer from './apps/roles/reducer';
import SnackbarReducer from './core/snackbar/reducer';

const reducers = combineReducers({
  customizer: CustomizerReducer,
  notesReducer: NotesReducer,
  chatReducer: ChatsReducer,
  contactsReducer: ContactsReducer,
  emailReducer: EmailReducer,
  ticketReducer: TicketReducer,
  form: FormReducer,
  login: LoginReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  user: UserReducer,
  vechicle:vechicles,
  logistics:Logistics,
  production:Production,
  loadingAdviceReport:LoadingAdviceReport,
  palletReport:PalletReport,
  StockReport:stockReport,
  snackbar: SnackbarReducer,
  // locker: LockerReducer,
  // customer: CustomerReducer,
  // garment: GarmentReducer,
  userDetails: UserDetailsReducer,
  // customerDetails: CustomerDetailsReducer,
  // garmentDetails: GarmentDetailsReducer,
  // lockerDetails: LockerDetailsReducer,
  // workOrder: WorkOrderReducer,
  // employee: EmployeeReducer,
  // workOrderDetails: WorkOrderDetailsReducer,
  // employeeDetails: EmployeeDetailsReducer,
  // department: DepartmentReducer,
  // departmentDetails: departmentDetailsReducer,
  // reason: ReasonReducer,
  organisation: OrganisationReducer,
  // inventory: InventoryReducer,
  role: RoleReducer,
});

export default reducers;
