import { SET_LOGISTICS_COUNT, SET_LOGISTICS_LIST, SET_LOGISTICS_LIST_EXPORT } from "./constants";


const initialState = {
    logisticsReportList: [],
    totalRecords:null,
    logisticCount:null,
    logisticsListExport:{}
  };
  
  export default (localState = initialState, action) => {
    switch (action.type) {  
      case SET_LOGISTICS_LIST: {
        const { logisticsList } = action.payload;
        console.log("vecicleLists:",logisticsList.value)
              return {
          ...localState,
          isGetUserPending: false,
          logisticsReportList: logisticsList && logisticsList.value,
          totalRecords: logisticsList["@odata.count"],
        };
   
      }
      case SET_LOGISTICS_LIST_EXPORT: {
        const { logisticsListExport } = action.payload;
        console.log("vecicleLists:",logisticsListExport.value)
              return {
          ...localState,
          isGetUserPending: false,
          logisticsListExport: logisticsListExport && logisticsListExport.value,
          // totalRecords: logisticsList["@odata.count"],
        };
   
      }
      case SET_LOGISTICS_COUNT: {
        const { logisticsCount } = action.payload;
        console.log("vecicleLists:",logisticsCount)
              return {
          ...localState,
          logisticCount:logisticsCount["@odata.count"],
        };
   
      }
      default: {
        return localState;
      }
    }
  };