import { SET_LOADING_ADVICE_REPORT_LIST, SET_LOADING_ADVICE_REPORT_LIST_EXPORT } from "./constants";

const initialState = {
    loadingAdviceReportList: [],
    totalRecords:null,
    loadingAdviceReportListExport:[]
  };
  
  export default (localState = initialState, action) => {
    switch (action.type) {  
      case SET_LOADING_ADVICE_REPORT_LIST: {
        const { loadingAdviceReportList } = action.payload;
        console.log("loadingAdviceReport:",loadingAdviceReportList.value)
              return {
          ...localState,
          loadingAdviceReportList: loadingAdviceReportList && loadingAdviceReportList.value,
          totalRecords: loadingAdviceReportList["@odata.count"],
        }
      }
      case SET_LOADING_ADVICE_REPORT_LIST_EXPORT: {
        const { loadingAdviceReportListExport } = action.payload;
        console.log("loadingAdviceReport:",loadingAdviceReportListExport.value)
              return {
          ...localState,
          loadingAdviceReportListExport: loadingAdviceReportListExport && loadingAdviceReportListExport.value,
          // totalRecords: loadingAdviceReportListExport["@odata.count"],
        }
      }
      default: {
        return localState;
      }
    }
  };