//eslint-disable-next-line
export const SET_STOCK_LIST_EXPORT='SET_STOCK_LIST_EXPORT'
export const SET_STOCK_LIST='SET_STOCK_LIST'
export const SET_STOCK_COUNT='SET_STOCK_COUNT'

export const WAREHOUSE_EXPORT_HEADERS = [
  "DATE",
    "PID",
    "WORKORDER #",
    "WORKORDER QTY",
    "PALLET QUANTITY",
    "MATERIAL BATCH #",
    "MATERIAL CODE",
    "FILLING LINE #",
    "PRODUCTION SHIFT",
    "LINE EXECUTIVE",
    "SCAN DATE",
    "STATUS",    
  ]