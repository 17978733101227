import { SET_PRODUCTION_COUNT, SET_PRODUCTION_LIST, SET_PRODUCTION_LIST_EXPORT } from "./constants";

const initialState = {
    ProductionCount:null,
    totalCount:null,
    ProductionList:{},
    ProductionListExport:{},

  };

  export default (localState = initialState, action) => {
    switch (action.type) {  
  
      case SET_PRODUCTION_COUNT: {
        const { productionCount } = action.payload;
        console.log("productionCount:",productionCount)
              return {
          ...localState,
          ProductionCount:productionCount["@odata.count"],
        //   logisticCount:productionCount,
        };
   
      }
      case SET_PRODUCTION_LIST: {
        const { productionList } = action.payload;
        console.log("productionList:",productionList)
              return {
          ...localState,
          totalCount:productionList["@odata.count"],
          ProductionList:productionList.value,
        };
   
      }
      case SET_PRODUCTION_LIST_EXPORT: {
        const { productionListExport } = action.payload;
        console.log("productionListExport:",productionListExport)
              return {
          ...localState,
          // totalCount:productionListExport["@odata.count"],
          ProductionListExport:productionListExport.value,
        };
   
      }
      default: {
        return localState;
      }
    }
  };