//eslint-disable-next-line
export const SET_PALLET_LIST='SET_PALLET_LIST'
export const SET_PALLET_LIST_EXPORT='SET_PALLET_LIST_EXPORT'
export const PALLET_EXPORT_HEADERS = [
    "PID",
    "WORKORDER#",
    "WORKORDER QTY",
    "PALLET QTY",
    "MATERIAL BATCH#",
    "MATERIAL CODE",
    "FILLING LINE#",
    "PRODUCTION SHIFT",
    "LINE EXECUTIVE",
    "PRODUCTION SCAN DATE",
    "WAREHOUSE SCAN DATE",
    "LOGISTICS SCAN DATE",
    "STATUS",
    "LAN #"
  ]