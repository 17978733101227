import { SET_PALLET_LIST, SET_PALLET_LIST_EXPORT } from "./constants";



const initialState = {
    PalletReportList: [],
    PalletListExport:[],
    totalRecords:null,
  };
  
  export default (localState = initialState, action) => {
    switch (action.type) {  
      case SET_PALLET_LIST: {
        const { palletList } = action.payload;
        console.log("palletList:",palletList.value)
              return {
          ...localState,
          PalletReportList: palletList && palletList.value,
          totalRecords: palletList["@odata.count"],
        };
   
      }
      case SET_PALLET_LIST_EXPORT: {
        const { palletListExport } = action.payload;
        console.log("palletListExport:",palletListExport.value)
              return {
          ...localState,
          PalletListExport: palletListExport && palletListExport.value,
          // totalRecords: palletList["@odata.count"],
        };
   
      }
      default: {
        return localState;
      }
    }
  };