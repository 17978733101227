//eslint-disable-next-line
export const SET_LOGISTICS_LIST_EXPORT='SET_LOGISTICS_LIST_EXPORT'
export const SET_LOGISTICS_LIST='SET_LOGISTICS_LIST'
export const SET_LOGISTICS_COUNT='SET_LOGISTICS_COUNT'
export const LOGISTICS_EXPORT_HEADERS = [
  "VEHICLE #",
  "DATE",
  "PID",
  "WORKORDER #",
  "WORKORDER QTY",
  "PALLET QUANTITY",
  "MATERIAL BATCH #",
  "MATERIAL CODE",
  "FILLING LINE #",
  "PRODUCTION SHIFT",
  "LINE EXECUTIVE",
  "SCAN DATE",
  "STATUS"
]