//eslint-disable-next-line
export const SET_PRODUCTION_COUNT='SET_PRODUCTION_COUNT'
export const SET_PRODUCTION_LIST='SET_PRODUCTION_LIST'
export const SET_PRODUCTION_LIST_EXPORT='SET_PRODUCTION_LIST_EXPORT'
export const PRODUCTION_EXPORT_HEADERS = [
    "DATE",
    "PID",
    "WORKORDER#",
    "WORKORDER QTY",
    "PALLET QTY",
    "MATERIAL BATCH#",
    "MATERIAL CODE",
    "FILLING LINE#",
    "PRODUCTION SHIFT",
    "LINE EXECUTIVE",
    "SCANNED DATE",
    "STATUS"
  ]