//eslint-disable-next-line
export const SET_LOADING_ADVICE_REPORT_LIST='SET_LOADING_ADVICE_REPORT_LIST'
export const SET_LOADING_ADVICE_REPORT_LIST_EXPORT='SET_LOADING_ADVICE_REPORT_LIST_EXPORT'

export const LOADING_ADVICE_REPORT_EXPORT_HEADERS = [
    "LAN #",
    "VEHICLE #",
    "PALLET CAPACITY",
    "LOADED QUANTITY",
    "LAN STATUS",
    "LOADING START TIME",
    "LOADING END TIME",
    

  ]