import { CLEAR_UPDATE_VECHICLE_ERROR, CLEAR_VECHICLE_ERROR, CLOSE_ACTION_MODAL, CLOSE_CREATE_VECHICLE_MODAL, CLOSE_EDIT_VECHICLE_MODAL, CREATE_VECHICLE_ERROR, CREATE_VECHICLE_PENDING, CREATE_VECHICLE_SUCCESS, OPEN_ACTION_MODAL, OPEN_CREATE_VECHICLE_MODAL, OPEN_EDIT_VECHICLE_MODAL, SET_VECHICLE_DETAILS, SET_VECHICLE_MASTER, SET_VECHICLE_MASTER_EXPORT, UPDATE_VECHICLE_ERROR, UPDATE_VECHICLE_PENDING, UPDATE_VECHICLE_SUCCESS } from "./constants";

const initialState = {
    List: [],
    VechicleListsExport:[],
    totalRecords:null,
    Details:{},
    isCreateVechicleModalOpen:false,
    isPending:false,
    errorMessage:null,
    isEditVechicleModalOpen:false,
    updateVechicleErrorMessage:'',
    isUpdateVechiclePending: false,
    isActionModalOpen:false,
  };
  
  export default (localState = initialState, action) => {
    switch (action.type) {  
      case SET_VECHICLE_MASTER: {
        const { vechicleLists } = action.payload;
        console.log("vecicleLists:",vechicleLists.value)
              return {
          ...localState,
          isGetUserPending: false,
          List: vechicleLists && vechicleLists.value,
          totalRecords: vechicleLists["@odata.count"],
        };
      }
      case SET_VECHICLE_MASTER_EXPORT: {
        const { vechicleListsExport } = action.payload;
        console.log("vechicleListsExport:",vechicleListsExport.value)
              return {
          ...localState,
          isGetUserPending: false,
          VechicleListsExport: vechicleListsExport && vechicleListsExport.value,
          // totalRecords: vechicleListsExport["@odata.count"],
        };
      }
      case SET_VECHICLE_DETAILS: {
        const { details } = action.payload;
        console.log("vecicleDetails:",details)
              return {
          ...localState,
          Details: details
   
        };
      }
      case OPEN_CREATE_VECHICLE_MODAL: {
        return {
          ...localState,
          isCreateVechicleModalOpen: true,
        };
      }
      case CLOSE_CREATE_VECHICLE_MODAL: {
        return {
            ...localState,
            isCreateVechicleModalOpen: false,
        };
      }
      case CREATE_VECHICLE_SUCCESS: {
        return {
          ...localState,
          isPending: false,
        };
      }
      case CREATE_VECHICLE_ERROR: {
        const { errorMessage } = action.payload;
        return {
          ...localState,
          errorMessage,
          isPending: false,
        };
      }
      case CREATE_VECHICLE_PENDING: {
        return {
          ...localState,
          isPending: true,
        };
      }
      case CLEAR_VECHICLE_ERROR: {
        return {
          ...localState,
          errorMessage: null,
        };
      }
      case OPEN_EDIT_VECHICLE_MODAL: {
        return {
          ...localState,
          isEditVechicleModalOpen: true,
        };
      }

      case CLOSE_EDIT_VECHICLE_MODAL: {
        return {
          ...localState,
          isEditVechicleModalOpen: false,
          updateVechicleErrorMessage: null,
        };
      }
      case CLEAR_UPDATE_VECHICLE_ERROR: {
        return {
          ...localState,
          updateVechicleErrorMessage: null,
          isUpdateVechiclePending: false,
        };
      }
      case UPDATE_VECHICLE_PENDING: {
        return {
          ...localState,
          isUpdateVechiclePending: true,
        };
      }
      case UPDATE_VECHICLE_SUCCESS: {
        return {
          ...localState,
          isUpdateVechiclePending: false,
        };
      }
      case UPDATE_VECHICLE_ERROR: {
        const { errorMessage } = action.payload;
        return {
          ...localState,
          updateVechicleErrorMessage: errorMessage,
          isUpdateVechiclePending: false,
        };
      }
      case OPEN_ACTION_MODAL: {
        return {
          ...localState,
          isActionModalOpen: true,
        };
      }
      case CLOSE_ACTION_MODAL: {
        return {
          ...localState,
          isActionModalOpen:false,
        };
      }
      default: {
        return localState;
      }
    }
  };