export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORGANISATION_ERROR = "CREATE_ORGANISATION_ERROR";
export const CREATE_ORGANISATION_PENDING = "CREATE_ORGANISATION_PENDING";
export const CLEAR_ORGANISATION_ERROR = "CLEAR_ORGANISATION_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_ORGANISATION_MODAL = "OPEN_CREATE_ORGANISATION_MODAL";
export const CLOSE_CREATE_ORGANISATION_MODAL = "CLOSE_CREATE_ORGANISATION_MODAL";
export const SET_ORGANISATIONS_PENDING = "SET_ORGANISATIONS_PENDING";
export const SET_ORGANISATIONS_LIST = "SET_ORGANISATIONS_LIST";
export const SET_ORGANISATION_DETAILS_INITIAL_STATE = "SET_ORGANISATION_DETAILS_INITIAL_STATE";
export const SET_ORGANISATION_DETAILS = "SET_ORGANISATION_DETAILS";
export const GET_ORGANISATION_DETAILS_PENDING = "GET_ORGANISATION_DETAILS_PENDING";
export const GET_ORGANISATION_DETAILS_SUCCESS = "GET_ORGANISATION_DETAILS_SUCCESS";
export const CLOSE_EDIT_ORGANISATION_MODAL = "CLOSE_EDIT_ORGANISATION_MODAL";
export const OPEN_EDIT_ORGANISATION_MODAL = "OPEN_EDIT_ORGANISATION_MODAL";
export const UPDATE_ORGANISATION_PENDING = "UPDATE_ORGANISATION_PENDING";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_ERROR = "UPDATE_ORGANISATION_ERROR";
export const CLEAR_UPDATE_ORGANISATION_ERROR = "CLEAR_UPDATE_ORGANISATION_ERROR";