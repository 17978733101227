import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
/***** Pages ****/
const Classic = Loadable(lazy(() => import('../views/dashboards/Classic')));

const Users = Loadable(lazy(() => import('../views/apps/users/users')));
const UserDetails = Loadable(lazy(() => import('../views/apps/users/userDetails/userDetails')));
const LogisticsReport =Loadable(lazy(() => import('../views/apps/Logistics_Report/logisticsReport')));
const WareHouse =Loadable(lazy(() => import('../views/apps/WareHouse/wareHouse')));
const Production =Loadable(lazy(() => import('../views/apps/Production/production')));
const Vechicles =Loadable(lazy(() => import('../views/apps/vechicles/vechicles')));
const PalletReport =Loadable(lazy(() => import('../views/apps/Pallet_Report/palletReport')));
const VechiclesDetails =Loadable(lazy(() => import('../views/apps/vechicles/vechicleDetails')));
const CreateVechicles =Loadable(lazy(() => import('../views/apps/vechicles/CreateVechicleMaster')));
const LoadingAdviceReport =Loadable(lazy(() => import('../views/apps/LoadingAdviceReport/loadingAdviceReport')));
// const EditVechicle =Loadable(lazy(() => import('../views/apps/vechicles/EditVechicleMaster')));
const Roles = Loadable(lazy(() => import('../views/apps/roles/roles')));
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/app',
    element: <FullLayout />,
    children: [
      { path: '', name: 'Home', element: <Navigate to="dashboard" /> },
      { path: 'dashboard', name: 'Classic', exact: true, element: <Classic /> },
      { path: 'users', name: 'Users', exact: true, element: <Users /> },
      { path: 'logisticsreport', name: 'LogisticsReport', exact: true, element: <LogisticsReport /> },
      { path: 'warehouse', name: 'WareHouse', exact: true, element: <WareHouse /> },
      { path: 'production', name: 'Production', exact: true, element: <Production /> },
      { path: 'vechicles', name: 'Vechicles', exact: true, element: <Vechicles /> },
      { path: 'palletreport', name: 'PalletReport', exact: true, element: <PalletReport /> },
      { path: 'vechicles/details/:id', name: 'VechiclesDetails', exact: true, element: <VechiclesDetails /> },
      { path: 'loadingadvicereport', name: 'LoadingAdviceReport', exact: true, element: <LoadingAdviceReport /> },
      { path: 'vechicles/create', name: 'CreateVechicles', exact: true, element: <CreateVechicles /> },
      // { path: 'vechicles/edit', name: 'EditVechicle', exact: true, element: <EditVechicle /> },
      { path: 'roles', name: 'Roles', exact: true, element: <Roles /> },
      { path: 'users/details/:id', name: 'UserDetails', exact: true, element: <UserDetails /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '', element: <Login /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: 'resetpassword/:code', element: <ResetPassword /> },
      ],
  },
];

export default ThemeRoutes;
