//eslint-disable-next-line
export const SET_VECHICLE_MASTER="SET_VECHICLE_MASTER";
export const SET_VECHICLE_MASTER_EXPORT="SET_VECHICLE_MASTER_EXPORT";
export const SET_VECHICLE_DETAILS="SET_VECHICLE_DETAILS";
export const OPEN_CREATE_VECHICLE_MODAL="OPEN_CREATE_VECHICLE_MODAL";
export const OPEN_ACTION_MODAL="OPEN_ACTION_MODAL";
export const CLOSE_ACTION_MODAL="CLOSE_ACTION_MODAL";
export const CLOSE_CREATE_VECHICLE_MODAL="CLOSE_CREATE_VECHICLE_MODAL";
export const CREATE_VECHICLE_SUCCESS="CREATE_VECHICLE_SUCCESS";
export const CREATE_VECHICLE_ERROR="CREATE_VECHICLE_ERROR";
export const CREATE_VECHICLE_PENDING="CREATE_VECHICLE_PENDING";
export const CLEAR_VECHICLE_ERROR="CLEAR_VECHICLE_ERROR";
export const OPEN_EDIT_VECHICLE_MODAL="OPEN_EDIT_VECHICLE_MODAL";
export const CLOSE_EDIT_VECHICLE_MODAL="CLOSE_EDIT_VECHICLE_MODAL";
export const CLEAR_UPDATE_VECHICLE_ERROR="CLEAR_UPDATE_VECHICLE_ERROR";
export const UPDATE_VECHICLE_PENDING="UPDATE_VECHICLE_PENDING";
export const UPDATE_VECHICLE_SUCCESS="UPDATE_VECHICLE_SUCCESS"
export const UPDATE_VECHICLE_ERROR="UPDATE_VECHICLE_ERROR"

export const VEHICLE_EXPORT_HEADERS = [
    " VEHICLE #",
    // " VEHICLE STATUS",
    "# OF PALLETTES",
    "CURRENT QTY",
    "CONTACT #",
    "CONTACT PERSON",
    "CREATED BY",
    "CREATED DATE",
    " STATUS",
    "ARRIVED DATE TIME",
    "DEPARTED DATE TIME"
]