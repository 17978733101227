import { SET_STOCK_COUNT, SET_STOCK_LIST, SET_STOCK_LIST_EXPORT } from "./constants";


const initialState = {
    stocksReportList: [],
    stocksReportListExport:[],
    totalRecords:null,
    stocksReportCount:null
  };
  
  export default (localState = initialState, action) => {
    switch (action.type) {  
      case SET_STOCK_LIST_EXPORT: {
        const { stockReportListExport } = action.payload;
        console.log("stockReportListExport:",stockReportListExport.value)
              return {
          ...localState,
          isGetUserPending: false,
          stocksReportListExport: stockReportListExport && stockReportListExport.value,
          // totalRecords: stockReportListExport["@odata.count"],
        };
   
      }
      case SET_STOCK_LIST: {
        const { stockReportList } = action.payload;
        console.log("stockReportList:",stockReportList.value)
              return {
          ...localState,
          isGetUserPending: false,
          stocksReportList: stockReportList && stockReportList.value,
          totalRecords: stockReportList["@odata.count"],
        };
   
      }
      case SET_STOCK_COUNT: {
        const { stockReportCount } = action.payload;
        console.log("stockReportCount:",stockReportCount)
              return {
          ...localState,
          stocksReportCount:stockReportCount["@odata.count"],
        };
   
      }
      default: {
        return localState;
      }
    }
  };